.bg{
    &-white{
        // background: var(--white) !important;
        --background: var(--white) !important;
    }

    &-light{
        --background: var(--ion-color-light) !important;
    }

    // backgrounds para contenedores especificos
    &-ion-content{
        --background: #f5f2f2 !important;
    }
}