$font-size-inputs: .8em;

html.md, html.ios {
  --ion-default-font: var(--app-font-family);
}

ion-content {
  --background: var(--ion-color-light);
}

.hidden {
  display: none;
  visibility: hidden;
}

.titulos {
  color: var(--ion-color-primary) !important;
  font-weight: var(--font-family-extra-bold);
  font-size: 20px;
}

.subtitulos {
  color: var(--ion-color-primary) !important;
  font-weight: var(--font-family-semi-bold);
  font-size: 12px;
}

.parrafos {
  font-weight: var(--font-family-regular);
}

.text-negrilla {
  font-weight: bold !important;
}

.border{
  &-left {
    border-left: solid 1px var(--ion-color-medium);
  }
  &-bottom {
    border-bottom: solid 1px var(--ion-color-border-grisclaro);
  }
}

.inputs-border-radius {
  border: solid 1px var(--ion-color-border-grisclaro);
  border-radius: 10px;
}

input[type="time"] {
  background: #ffffff;
  border: none;
  border-bottom: 1px solid #dedede;
  width: 80%;
  
  &:focus-visible{
  outline: 0px;
  }
}

.enlaces-no-decoration{
    text-decoration: none;
    margin-left: 5px;
    color: var(--ion-color-dark);
}

.m{
  &b{
    &-0{
      margin-bottom: 0px !important;
    }
  }
}

.d{
  &-ib{
    display: inline-block;
  }
}

.margin-top--5 {
  margin-top: -5px !important;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-horizontal-5 {
  padding: 0px 5px;
}

.margen-icon-menu {
  margin-right: -15px;
  margin-left: 0px;
}

.icon-red-actionSheet .sc-ion-action-sheet-md {
  color: var(--ion-color-danger);
}

.cont-items-formulario {
  border-bottom: 1px solid var(--ion-color-border-grisclaro);
  margin-left: -16px !important;
  margin-right: -16px !important;
  margin-top: 10px;
  padding-left: 16px;
  padding-bottom: 5px;

  .cont-label-form {
    height: 25px;
    margin-bottom: 5px;

    .icon-form {
      // width: 20px;
      // height: 20px;
      // float: left;
      margin-right: 5px;
    }
  }

  .label-contenido-form {
    font-size: $font-size-inputs;
    color: var(--ion-color-dark-tint);
  }
}

.icon-label {
  background: -webkit-linear-gradient(#4FACFE, #00F2FE);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4em;
}

.select-text {
  margin-left: -16px !important;
  margin-top: -10px;
  font-size: $font-size-inputs !important;
  color: var(--ion-color-dark-tint);
}

.nota-fecha-cita{
  margin-top: -0.7rem;
  font-size: .6rem;
  text-align: right;
  padding-inline-start: 5px;
  margin-inline-start: 5px;
}

.alert-warning {
  --background: #ffeeba !important;
  background: #ffeeba !important;
  border: solid 1px #FFC916 !important;
}

/*Error campos de formularios*/
.error-message {
  color: red;
  font-size: 10px;
  margin-left: 0px;
  margin-top: 5px;
}

.toast-edit {
  text-align: center;
}

ion-card-title {
  border-bottom: 1px solid var(--ion-color-border-grisclaro);
}

.searchbar-input.sc-ion-searchbar-md {
  padding-inline-start: 40px;
}

.searchbar-input.sc-ion-searchbar-md {
  border-radius: 10px !important;
  font-size: 15px;
}

.popover-content.sc-ion-popover-ios {
  width: 300px;
}

.sc-ion-alert-ios-h{
  --min-width: 300px;
}

.modal-terminosycondiciones>.alert-wrapper.sc-ion-alert-ios {
  --max-width: 80% !important;
  .alert-message.sc-ion-alert-ios{
    max-height: 300px;
  }
}

ion-calendar{
  padding: 0px !important;
  
  .month-packer-item{
    button{
      color: #757575 !important;
      --color: #757575 !important;
    }
  }
}

// dia seleccionado del compoente de calendario
button.days-btn.dia-con-citas{
  background-color: #F2F2F1 !important;
  color: #000000 !important;

  &.on-selected{
    background-color: var(--ion-color-primary) !important;
    color: #FFFFFF !important;
  }
}

ion-datetime {
  --background: #ffffff;
  --background-rgb: 255, 255, 255;
  color: var(--ion-color-primary);
  border-radius: 0px;
  box-shadow: rgba(var(--ion-color-primary-rgb), 0.3) 0px 10px 15px -3px;

  div.calendar-days-of-week{
    color: var(--ion-color-primary) !important;
  }
}

ion-datetime-button{

  &.align-left {
    display: flex;
    justify-content: left;
    margin-left: -11px;
  }
  
  &.bg-light::part(native) {
    background: var(--ion-color-light);
  }

  &.bg-white::part(native) {
    background: var(--white);
  }
}
