.font-size {
  &-xs {
    font-size: .8em !important;
  }

  &-sm {
    font-size: .9em !important;
  }

  &-md {
    font-size: .7em !important;
  }

  &-lg {
    font-size: 1.2em !important;
  }
  
  &-xl {
    font-size: 1.7em !important;
  }
}

.tex {
  &-color {
      &-medium {
          color: var(--ion-color-medium);
      }
      &-primary {
          color: var(--ion-color-primary);
      }
      &-danger {
        color: var(--ion-color-danger);
      }
  }
}