// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #1049A2;
  --ion-color-primary-rgb: 16, 73, 162;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0e408f;
  --ion-color-primary-tint: #285bab;

  /** secondary **/
  --ion-color-secondary: #4988d0;
  --ion-color-secondary-rgb: 73, 136, 208;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4078b7;
  --ion-color-secondary-tint: #5b94d5;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #59A507;
  --ion-color-success-rgb: 89, 165, 7;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #4e9106;
  --ion-color-success-tint: #6aae20;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #E73119;
  --ion-color-danger-rgb: 231, 49, 25;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cb2b16;
  --ion-color-danger-tint: #e94630;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #9A9A9A;
  --ion-color-medium-rgb: 154, 154, 154;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #888888;
  --ion-color-medium-tint: #a4a4a4;

  /** light **/
  --ion-color-light: #F0F0F0; // f5f2f2
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-border-grisclaro: #dedede;

  --ion-background-color: #ffffff;
	--ion-background-color-rgb: 255,255,255;

	--ion-text-color: #000000;
	--ion-text-color-rgb: 0,0,0;

	--ion-color-step-50: #f2f2f2;
	--ion-color-step-100: #e6e6e6;
	--ion-color-step-150: #d9d9d9;
	--ion-color-step-200: #cccccc;
	--ion-color-step-250: #bfbfbf;
	--ion-color-step-300: #b3b3b3;
	--ion-color-step-350: #a6a6a6;
	--ion-color-step-400: #999999;
	--ion-color-step-450: #8c8c8c;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #737373;
	--ion-color-step-600: #666666;
	--ion-color-step-650: #595959;
	--ion-color-step-700: #4d4d4d;
	--ion-color-step-750: #404040;
	--ion-color-step-800: #333333;
	--ion-color-step-850: #262626;
	--ion-color-step-900: #191919;
	--ion-color-step-950: #0d0d0d;

  --white: #FFFFFF;
  // fonts
  --app-font-family: 'Poppins', sans-serif;
  --font-family-extra-bold: 800;
  --font-family-semi-bold: 600;
  --font-family-regular: 400;
  --font-family-light: 300;
  --font-family-extra-light: 200;
  --font-family-extra-thin: 100;
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  // body {
  //   --ion-color-primary: #428cff;
  //   --ion-color-primary-rgb: 66,140,255;
  //   --ion-color-primary-contrast: #ffffff;
  //   --ion-color-primary-contrast-rgb: 255,255,255;
  //   --ion-color-primary-shade: #3a7be0;
  //   --ion-color-primary-tint: #5598ff;

  //   --ion-color-secondary: #50c8ff;
  //   --ion-color-secondary-rgb: 80,200,255;
  //   --ion-color-secondary-contrast: #ffffff;
  //   --ion-color-secondary-contrast-rgb: 255,255,255;
  //   --ion-color-secondary-shade: #46b0e0;
  //   --ion-color-secondary-tint: #62ceff;

  //   --ion-color-tertiary: #6a64ff;
  //   --ion-color-tertiary-rgb: 106,100,255;
  //   --ion-color-tertiary-contrast: #ffffff;
  //   --ion-color-tertiary-contrast-rgb: 255,255,255;
  //   --ion-color-tertiary-shade: #5d58e0;
  //   --ion-color-tertiary-tint: #7974ff;

  //   --ion-color-success: #2fdf75;
  //   --ion-color-success-rgb: 47,223,117;
  //   --ion-color-success-contrast: #000000;
  //   --ion-color-success-contrast-rgb: 0,0,0;
  //   --ion-color-success-shade: #29c467;
  //   --ion-color-success-tint: #44e283;

  //   --ion-color-warning: #ffd534;
  //   --ion-color-warning-rgb: 255,213,52;
  //   --ion-color-warning-contrast: #000000;
  //   --ion-color-warning-contrast-rgb: 0,0,0;
  //   --ion-color-warning-shade: #e0bb2e;
  //   --ion-color-warning-tint: #ffd948;

  //   --ion-color-danger: #ff4961;
  //   --ion-color-danger-rgb: 255,73,97;
  //   --ion-color-danger-contrast: #ffffff;
  //   --ion-color-danger-contrast-rgb: 255,255,255;
  //   --ion-color-danger-shade: #e04055;
  //   --ion-color-danger-tint: #ff5b71;

  //   --ion-color-dark: #f4f5f8;
  //   --ion-color-dark-rgb: 244,245,248;
  //   --ion-color-dark-contrast: #000000;
  //   --ion-color-dark-contrast-rgb: 0,0,0;
  //   --ion-color-dark-shade: #d7d8da;
  //   --ion-color-dark-tint: #f5f6f9;

  //   --ion-color-medium: #989aa2;
  //   --ion-color-medium-rgb: 152,154,162;
  //   --ion-color-medium-contrast: #000000;
  //   --ion-color-medium-contrast-rgb: 0,0,0;
  //   --ion-color-medium-shade: #86888f;
  //   --ion-color-medium-tint: #a2a4ab;

  //   --ion-color-light: #222428;
  //   --ion-color-light-rgb: 34,36,40;
  //   --ion-color-light-contrast: #ffffff;
  //   --ion-color-light-contrast-rgb: 255,255,255;
  //   --ion-color-light-shade: #1e2023;
  //   --ion-color-light-tint: #383a3e;
  // }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body {
  //   --ion-background-color: #000000;
  //   --ion-background-color-rgb: 0,0,0;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255,255,255;

  //   --ion-color-step-50: #0d0d0d;
  //   --ion-color-step-100: #1a1a1a;
  //   --ion-color-step-150: #262626;
  //   --ion-color-step-200: #333333;
  //   --ion-color-step-250: #404040;
  //   --ion-color-step-300: #4d4d4d;
  //   --ion-color-step-350: #595959;
  //   --ion-color-step-400: #666666;
  //   --ion-color-step-450: #737373;
  //   --ion-color-step-500: #808080;
  //   --ion-color-step-550: #8c8c8c;
  //   --ion-color-step-600: #999999;
  //   --ion-color-step-650: #a6a6a6;
  //   --ion-color-step-700: #b3b3b3;
  //   --ion-color-step-750: #bfbfbf;
  //   --ion-color-step-800: #cccccc;
  //   --ion-color-step-850: #d9d9d9;
  //   --ion-color-step-900: #e6e6e6;
  //   --ion-color-step-950: #f2f2f2;

  //   --ion-toolbar-background: #0d0d0d;

  //   --ion-item-background: #000000;

  //   --ion-card-background: #1c1c1d;
  // }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  // .md body {
  //   --ion-background-color: #121212;
  //   --ion-background-color-rgb: 18,18,18;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255,255,255;

  //   --ion-border-color: #222222;

  //   --ion-color-step-50: #1e1e1e;
  //   --ion-color-step-100: #2a2a2a;
  //   --ion-color-step-150: #363636;
  //   --ion-color-step-200: #414141;
  //   --ion-color-step-250: #4d4d4d;
  //   --ion-color-step-300: #595959;
  //   --ion-color-step-350: #656565;
  //   --ion-color-step-400: #717171;
  //   --ion-color-step-450: #7d7d7d;
  //   --ion-color-step-500: #898989;
  //   --ion-color-step-550: #949494;
  //   --ion-color-step-600: #a0a0a0;
  //   --ion-color-step-650: #acacac;
  //   --ion-color-step-700: #b8b8b8;
  //   --ion-color-step-750: #c4c4c4;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dbdbdb;
  //   --ion-color-step-900: #e7e7e7;
  //   --ion-color-step-950: #f3f3f3;

  //   --ion-item-background: #1e1e1e;

  //   --ion-toolbar-background: #1f1f1f;

  //   --ion-tab-bar-background: #1f1f1f;

  //   --ion-card-background: #1e1e1e;
  // }
}